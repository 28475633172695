import React, {
  useEffect, useLayoutEffect, useRef, useState
} from 'react'
import { useLocation } from 'react-router-dom'
import { CopyOutlined } from '@ant-design/icons'
import {
  Col, Input as AntInput,
  Modal, Row, Spin, Typography
} from 'antd'

import { Button } from 'src/components/Button'
import { FeatureCard } from 'src/components/FeatureCard'
import { TextArea } from 'src/components/Input'
import { PageStepper } from 'src/components/PageStepper'
import { Spacer } from 'src/components/Spacer'
import { carInfo, featureObj } from 'src/types/descriptionBuilder'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'

import './styles.scss'

const { Title, Text } = Typography

export default function DescriptionConfirmation() {
  const [isLoading, setIsLoading] = useState(false)
  const [gpt3Response, setGpt3Response] = useState('')
  const [error, setError] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [initialFeatures, setInitialFeatures] = useState<featureObj>({})
  const [carInfo, setCarInfo] = useState<carInfo>({
    make: '', model: '', year: '', odometer: '', exteriorCondition: 8, interiorCondition: 8, mechanicalCondition: 8
  })
  const [orderedCarInfo, setOrderedCarInfo] = useState<[string, string | number][]>([])

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const vid = queryParams.get('vid')
  const [descriptionGenerated, setDescriptionGenerated] = useState(false)

  useLayoutEffect(() => {
    console.log('scrolling to top')
    window.scrollTo(0, 0)
  }, [isLoading])

  useEffect(() => {
    const fetchData = async () => {
      if (vid) {
        try {
          const api = await authApiAxiosWithRefresh()
          const response: any = await api.get(`/ai-description/get/car/${vid}`)
          const allData = response.data.carData
          const features: featureObj = allData.carFeatures
          const cleanFeatures = cleanInitialFeatures(features)

          const { carInfo } = allData
          console.log('carInfo:', carInfo)
          setInitialFeatures(cleanFeatures)
          setCarInfo(carInfo)
        } catch (error) {
          console.error('Error parsing data:', error)
        }
      }
    }

    fetchData()
  }, [vid])

  useEffect(() => {
    if (carInfo) {
      const keyOrder = ['year', 'make', 'model', 'trim', 'vin', 'odometer', 'modificationsAndMaintenance', 'exteriorCondition', 'exteriorIssues', 'interiorCondition', 'interiorIssues', 'mechanicalCondition', 'mechanicalIssues']
      const sortedEntries = Object.entries(carInfo).sort(
        ([keyA], [keyB]) => keyOrder.indexOf(keyA) - keyOrder.indexOf(keyB)
      )
      console.log('sortedEntries:', sortedEntries)
      setOrderedCarInfo(sortedEntries)
    }
  }, [carInfo])

  function cleanInitialFeatures(initFeatures: { [key: string]: { text: string; initialStatus: 'pending' | 'added' }[] }) {
    const cleanedFeatures: { [key: string]: { text: string; initialStatus: 'pending' | 'added' }[] } = {}

    Object.entries(initFeatures).forEach(([category, features]) => {
      if (features.length !== 0) {
        cleanedFeatures[category] = features.filter((feature) => feature.initialStatus === 'added')
      }
    })

    return cleanedFeatures
  }

  const getGpt3Description = async () => {
    setIsLoading(true)
    try {
      const cleanedFeatures = cleanInitialFeatures(initialFeatures)
      const api = await authApiAxiosWithRefresh()
      const response: any = await api.post('/ai-description/get/description', {
        prompt: JSON.stringify(carInfo, null, 2) + JSON.stringify(cleanedFeatures, null, 2)
      })
      setGpt3Response(response.data.content)
      // setIsModalVisible(true)
      setDescriptionGenerated(true) // Set to true after a successful generation
      setCurrentPage(3) // Set to page 1 to switch view
    } catch (error: any) {
      console.error('Error fetching ChatGPT response:', error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const handleStatusChange = (newStatus: 'added' | 'pending', category: string, feature: string | {key: string, value: any}) => {
    // update initial status to avoid re-rendering with old status

    setInitialFeatures((prevFeatures) => {
      const newFeatures = { ...prevFeatures }
      const featureText = typeof feature === 'string' ? feature : feature.key
      const featureIndex = newFeatures[category].findIndex((f) => f.text === featureText)
      if (featureIndex !== -1) {
        newFeatures[category][featureIndex].initialStatus = newStatus
      }
      return newFeatures
    })
  }

  const handleTextChange = (newText: string, category: string, prevText: string) => {
    setInitialFeatures((prevFeatures) => {
      const newFeatures = { ...prevFeatures }
      const featureIndex = newFeatures[category].findIndex((f) => f.text === prevText)
      if (featureIndex !== -1) {
        newFeatures[category][featureIndex].text = newText
      } else if (newText.length > 0) {
        // Add new feature if it doesn't exist
        newFeatures[category].push({
          text: newText,
          initialStatus: 'added'
        })
      }
      return newFeatures
    })
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const formatKey = (key: string) => {
    return key
      .replace(/([A-Z])/g, ' $1') // Add space before capital letters
      .replace(/^./, (str) => str.toUpperCase()) // Capitalize the first letter
  }

  const [isCopied, setIsCopied] = useState(false)

  const copyToClipboard = () => {
    navigator.clipboard.writeText(gpt3Response)
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 2000)
  }

  const selectedFeatureCards = () => {
    return (
      <div>
        <h1>Selected Features</h1>
        <Col span={24}>
          <Row gutter={16}>
            {initialFeatures && Object.entries(initialFeatures).map(([category, features], index) => (
              Array.isArray(features) && features.map((feature, i) => (
                <Col key={i} xs={12} sm={12} md={8} lg={8}>
                  <FeatureCard
                    text={feature.text}
                    initialStatus={feature.initialStatus}
                    onStatusChange={(newStatus) => handleStatusChange(newStatus, category, feature.text)}
                    onEdit={(newText) => handleTextChange(newText, category, feature.text)}
                  />
                </Col>
              ))
            ))}
          </Row>
        </Col>
      </div>
    )
  }

  // const step = parseInt(queryParams.get('step'), 10) || 3; // Default to page 3 if not provided
  const [currentPage, setCurrentPage] = useState(2)
  const totalPages = 4

  // const handlePageClick = (page: number) => {
  //   if (vid) {
  //     try {
  //       if (currentPage <= 2 &&(page === 1 || page === 2)) {
  //         window.location.href = `http://localhost:3001/#/description-builder?vid=${vid}`;
  //         return; // Exit to prevent further steps in this function
  //       }

  //       if (page === 3 && !descriptionGenerated) {
  //         setError('Please generate the description before navigating to this page.');
  //         return;
  //       }

  //       setCurrentPage(page);
  //     } catch (error) {
  //       console.error('Error parsing data:', error)
  //     }
  //   }
  // };

  const handlePageClick = (page: number) => {
    if (vid) {
      try {
        // Define the section based on the page clicked
        let section
        if (page === 0) {
          section = 'page1'
        } else if (page === 1) {
          section = 'page2'
        }

        // If navigating to page 1 or page 2, update the URL with the section
        if (section) {
          const currentUrl = new URL(window.location.href)
          currentUrl.hash = `#/description-builder?vid=${vid}#${section}`
          const newUrl = currentUrl.toString()
          window.location.href = newUrl
          return // Exit to prevent further steps in this function
        }

        // Check for page 3 and if description has been generated
        if (page === 3 && !descriptionGenerated) {
          setError('Please generate the description before navigating to this page.')
          return
        }

        setCurrentPage(page) // Update the current page state
      } catch (error) {
        console.error('Error parsing data:', error)
      }
    }
  }

  return (

    <Spin className="spinner" spinning={isLoading} size="large">
      <Row className="specific-vehicle-information-page">

        <Col span={24}>
          <PageStepper currentPage={currentPage} totalPages={totalPages} setCurrentPage={handlePageClick} />
        </Col>

        <Col
          className={`page-content ${currentPage === 2 ? 'swipe-in' : 'swipe-out'}`}
          style={{ display: currentPage === 2 ? 'block' : 'none' }}
        >
          <Col className="flex-col" span={20} offset={2}>
            <Spacer space={15} />
            <Title className="specific-vehicle-information-page__title" level={4}>
              Confirm Details and Generate Description
            </Title>
            {orderedCarInfo && (
              <Title level={5}>
                {orderedCarInfo.map(([key, value]) => (
                  <div key={key}>{`${formatKey(key)}: ${value}`}</div>
                ))}
              </Title>
            )}
            {selectedFeatureCards()}
            <Spacer space={15} />
            <Button
              className="description-form__btn"
              type="primary"
              color="primary"
              onClick={getGpt3Description}
              disabled={isLoading}
            >
              Generate description
            </Button>
          </Col>
        </Col>
        {/* Page 4 - ChatGpt response */}
        <Col
          className={`page-content ${currentPage === 3 ? 'swipe-in' : 'swipe-out'}`}
          style={{ display: currentPage === 3 ? 'block' : 'none' }}
        >
          <Col className="flex-col" span={20} offset={2}>
            <Title> Generated Description</Title>
            <Modal
              className="description-modal"
              visible={isModalVisible}
              onOk={handleOk}
              closable={false}
              footer={[
                <Button key="ok" type="primary" onClick={handleOk}>
                  Ok
                </Button>
              ]}
            >
              <div className="text-area-wrapper">
                <Button
                  onClick={copyToClipboard}
                  className="text-area-copy-button"
                >
                  {isCopied ? <span className="copied-text">Copied!</span> : <CopyOutlined className="copy-icon" />}
                </Button>
                <TextArea
                  value={gpt3Response}
                  rows={20}
                  className="text-area-padding"
                />

              </div>
            </Modal>
            {gpt3Response && gpt3Response.trim() !== '' ? (
              <>
                <div className="text-area-wrapper">
                  <Button
                    onClick={copyToClipboard}
                    className="text-area-copy-button"
                  >
                    {isCopied ? <span className="copied-text">Copied!</span> : <CopyOutlined className="copy-icon" />}
                  </Button>
                  <TextArea
                    value={gpt3Response}
                    rows={20}
                    className="text-area-padding"
                  />

                </div>
                <Spacer space={70} />
              </>
            ) : null}

          </Col>
        </Col>

      </Row>
    </Spin>
  )
}
