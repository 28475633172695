import React, {
  useContext, useEffect, useMemo, useState
} from 'react'
import { useNavigate } from 'react-router-dom'
import { CheckCircleTwoTone, ExclamationCircleTwoTone } from '@ant-design/icons'
import { loadStripe } from '@stripe/stripe-js'
import {
  Col, Row, Space, Typography
} from 'antd'
import classNames from 'classnames'
import { set } from 'lodash-es'

import { Button } from 'src/components/Button'
import { HeaderLayout } from 'src/components/HeaderLayout'
import { Spacer } from 'src/components/Spacer'
import VerifyIdentityButton from 'src/components/VerifyIdentityButton/VerifyIdentityButton'
// temp while all users are dealers. Get user token
import { useAuthen } from 'src/hooks/useAuthen'
import { EnumRoute } from 'src/router/types'
import { store } from 'src/store/store'
import { VerificationSession } from 'src/types/identity'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'

import historyIcon from '../../../assets/svgIcons/V-history.svg'
import { useDisplay } from '../../../hooks/useDisplay'
import { LIMIT_VERIFICATION_SESSIONS } from '../../../utils/constants'

import './styles.scss'

const { Title, Text } = Typography

interface VerificationNotifProps {
  resultObject: VerificationSession | null;
  onSessionIdReceived: (id: string, status:string, error:string | undefined) => void;
}

const VerificationNotif: React.FC<VerificationNotifProps> = ({ resultObject, onSessionIdReceived }) => {
  useEffect(() => {
    if (resultObject) {
      onSessionIdReceived(resultObject.id, resultObject.status, resultObject.last_error?.code)
    }
  }, [resultObject, onSessionIdReceived])

  if (resultObject) {
    if (resultObject.status === 'verified') {
      return (
        <Space className="notif-container">
          <Row className="notif-header">
            <Title type="success">Customer Verified</Title>
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          </Row>
        </Space>
      )
    } if (resultObject.status === 'processing') {
      return (
        <Row className="notif-header">
          <Title type="secondary">Processing Verification...</Title>
        </Row>
      )
    }
    return (
      <Space className="notif-container">
        <Row className="notif-header">
          <Title type="danger">Verification Failed </Title>
          <ExclamationCircleTwoTone twoToneColor="#eb2f96" />
        </Row>
        <Row className="notif-row">
          <Text>Error Code:</Text>
          <Text>{resultObject.last_error?.code}</Text>
        </Row>
        <Row className="notif-row">
          <Text>Reason:</Text>
          <Text>{resultObject.last_error?.reason}</Text>
        </Row>
      </Space>
    )
  }
  return null
}

const VerifyIdentityHomePage: React.FC = () => {
  const navigate = useNavigate()
  const globalStore = useContext(store)
  const stripePromise = useMemo(() => loadStripe(globalStore.state.misc.stripePublishableKey), [globalStore])
  const { isMobileDevice } = useDisplay()

  // get org id
  const [dealerId, setDealerId] = useState<string | null>(null)
  const [orgName, setOrgName] = useState<string | null>(null)
  const [noDealer, setnoDealer] = useState<boolean | null>(null)
  const [isOverLimit, setIsOverLimit] = useState<boolean>(false)
  const [resultObject, setResultObject] = useState<VerificationSession | null>(null)
  const [sessionsRemaining, setSessionsRemaining] = useState<number | null>(null)
  const { currentUser } = useAuthen()

  useEffect(() => {
    const fetchOrgData = async () => {
      try {
        const response = await (await authApiAxiosWithRefresh()).get('user/orgData')
        setDealerId(response.data.id)
        setOrgName(response.data.dealershipName)
      } catch (error) {
        // temp while all users are dealers. Get user id
        // setnoDealer(true)
        if (currentUser?.uid) {
          setDealerId(currentUser?.uid)
          setOrgName('User')
        }
        console.error('Error fetching dealerId')
      }
    }

    fetchOrgData()
  }, [])

  useEffect(() => {
    const fetchSessions = async () => {
      if (dealerId) {
        try {
          const sessions = await (await authApiAxiosWithRefresh()).get(`stripe-identity/list_verification_session/${dealerId}`)
          setSessionsRemaining(5 - sessions.data.length)
          if (sessions.data.length > 5) {
            setIsOverLimit(true)
          }
        } catch (error) {
          console.error('Error fetching sessions')
        }
      }
    }

    fetchSessions()
  }, [dealerId, resultObject])

  const handleDataReceived = (data: any) => {
    if (data === null) {
      setResultObject(null)
    } else {
      setResultObject(data.object)
    }
  }

  const [verificationId, setVerificationId] = useState<string | null>(null)
  const handleVerificationId = (id: string, status: string, error: string | undefined) => {
    // don't show continue button if status is processing or if consent was declined
    if (status !== 'processing' && error !== 'consent_declined') {
      setVerificationId(id)
    } else { setVerificationId(null) }
  }

  const handleVerificationDetailsClick = () => {
    if (verificationId && orgName) {
      navigate(EnumRoute.VerifyIdentityDetails.replace(':orgName', orgName).replace(':verificationId', verificationId))
    } else {
      console.error('verificationId is null')
    }
  }

  const handleVerificationHistoryClick = () => {
    if (orgName) {
      navigate(EnumRoute.VerifyIdentityHistory.replace(':orgName', orgName))
    } else {
      console.error('dealerId is null')
    }
  }

  const title = 'Identity Verification Home'
  const subtitle = 'Start a verification session or view previous results'
  const renderRightItems = () => {
    if (!isMobileDevice) {
      return (
        <Text>
          Dealer:
          {' '}
          {orgName}
        </Text>
      )
    }
    return null
  }

  const [space, setSpace] = useState(50) // Space between widget
  const [mpace, setMpace] = useState(30) // Space on top
  const [tpace, setTpace] = useState(12) // Space between header
  useEffect(() => {
    const updateSpace = () => {
      if (window.innerWidth > 550) {
        setSpace(50)
      } else {
        setSpace(0)
      }
    }
    updateSpace()
    window.addEventListener('resize', updateSpace)
    return () => {
      window.removeEventListener('resize', updateSpace)
    }
  }, [])

  useEffect(() => {
    const updateMpace = () => {
      if (window.innerWidth > 550) {
        setMpace(30)
      } else {
        setMpace(0)
      }
    }
    updateMpace()
    window.addEventListener('resize', updateMpace)
    return () => {
      window.removeEventListener('resize', updateMpace)
    }
  }, [])

  useEffect(() => {
    const updateTpace = () => {
      if (window.innerWidth > 550) {
        setTpace(12)
      } else {
        setTpace(0)
      }
    }
    updateTpace()
    window.addEventListener('resize', updateTpace)
    return () => {
      window.removeEventListener('resize', updateTpace)
    }
  }, [])

  return (
    <>
      <Spacer space={15} />
      <HeaderLayout
        title={title}
        hasGoBack={false}
        subtitle={subtitle}
        rightItems={renderRightItems}
      >
        {LIMIT_VERIFICATION_SESSIONS && (
          <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', height: '2vh'
          }}
          >
            <Text style={{ fontSize: '12px', textAlign: 'center' }}>
              Free Version verifications remaining:
              {' '}
              {sessionsRemaining}
            </Text>
          </div>
        )}
        <Row style={{
          display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center'
        }}
        >
          <Col
            className="transaction-form-wrapper flex-col"
            span={20}
            style={{
              height: '100%', overflow: 'auto', width: '100%', alignItems: 'center'
            }}
          >

            <Spacer space={mpace} />
            {noDealer ? (
              <Text>User not signed up as dealer. Must sign up as a dealer to access Pacer Identity</Text>
            ) : (
              <>
                <VerificationNotif resultObject={resultObject} onSessionIdReceived={handleVerificationId} />

                <Spacer space={tpace} />

                {isOverLimit && LIMIT_VERIFICATION_SESSIONS ? (
                  <Text>
                    You have reached the maximum number of identity verifications.
                    We apologize for the inconvenience.
                    While we work on developing this as a paid feature, you can still view and download reports of your
                    previous verifications. Thanks. - The Pacer Team
                  </Text>
                ) : (
                  <VerifyIdentityButton stripePromise={stripePromise} onDataReceived={handleDataReceived} dealerId={dealerId} />
                )}

                <Spacer space={7} />

                {dealerId && verificationId && (
                  <Button
                    type="primary"
                    color="secondary"
                    size="large"
                    // to do styling
                    className="transaction-form__btn"
                    onClick={handleVerificationDetailsClick}
                  >
                    View Verification Results
                  </Button>
                )}

              </>
            )}

            <Spacer space={space} />

            {/* {dealerId && (
              <Button
                type="primary"
                color="info"
                // to do styling
                className="transaction-form__btn"
                onClick={handleVerificationHistoryClick}
              >
                View Verification History
              </Button>
            )} */}
            {dealerId && (
              <div className="verify-identity-widget">
                <img
                  src={historyIcon}
                  alt="History Icon"
                  className="verify-identity-widget__icon"
                />
                <div className="verify-identity-widget__content">
                  <div className="verify-identity-widget__text">
                    View Verification History
                  </div>
                  <Button
                    className="verify-identity-widget__button"
                    // type="primary"
                    // color="info"
                    onClick={handleVerificationHistoryClick}
                  >
                    Start
                  </Button>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </HeaderLayout>
    </>
  )
}

export default VerifyIdentityHomePage
