import React, { useContext, useEffect, useState } from 'react'
import {
  Link,
  useLocation,
  useNavigate
} from 'react-router-dom'
import {
  CarOutlined,
  CompassOutlined,
  DollarCircleOutlined,
  ExclamationCircleFilled,
  FileDoneOutlined,
  FileProtectOutlined,
  HomeOutlined,
  IdcardOutlined,
  MailOutlined,
  MenuOutlined,
  // PhoneFilled,
  PlusSquareOutlined,
  ReconciliationOutlined,
  UserOutlined
} from '@ant-design/icons'
import type { MenuProps } from 'antd'
import {
  Avatar, ConfigProvider, Drawer, Menu
} from 'antd'

import background from 'src/assets/image@2x.png'
import logo from 'src/assets/logo.png'
import logoWhite from 'src/assets/logo-white.png'
import { Button } from 'src/components/Button'
import { Navigation } from 'src/components/Navigation'
import { useAuthen } from 'src/hooks/useAuthen'
import { useTheme } from 'src/hooks/useTheme'
import { EnumRoute } from 'src/router/types'
import { store } from 'src/store/store'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'
import { EnumTheme } from 'src/utils/types'

import './TopBar.scss'

export default function TopBar() {
  const { theme } = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const globalStore = useContext(store)
  const { currentUser } = useAuthen()

  const [isDealer, setIsDealer] = useState<boolean | null>(false)
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)

  useEffect(() => {
    const isOrg = async () => {
      if (!currentUser) {
        return
      }

      try {
        const response = await (await authApiAxiosWithRefresh()).get('user/isOrg')
        setIsDealer(response.data)
      } catch (error) {
        setIsDealer(false)
      }
    }

    isOrg()
  }, [currentUser])

  const handleMenuClick = () => {
    setIsDrawerVisible(true)
  }

  const handleDrawerClose = () => {
    setIsDrawerVisible(false)
  }

  const handleSubMenuChange = (keys: string[]) => {
    setOpenKeys(keys)
  }

  const [openKeys, setOpenKeys] = useState<string[]>([])

  const topNavItems = [
    {
      title: 'Start Transactions',
      route: EnumRoute.NewTransaction,
      icon: <DollarCircleOutlined className="nav-item-icon" />,
      activeIcon: <DollarCircleOutlined className="nav-item-icon" />
    },
    {
      title: 'Shipping',
      route: EnumRoute.ShippingAndTracking,
      icon: <CarOutlined className="nav-item-icon" />,
      activeIcon: <CarOutlined className="nav-item-icon" />
    },
    // {
    //   title: 'Warranty',
    //   route: EnumRoute.Warranty,
    //   icon: <FileProtectOutlined className="nav-item-icon" />,
    //   activeIcon: <PlusSquareOutlined className="nav-item-icon" />
    // },
    {
      title: 'Verify ID',
      route: EnumRoute.VerifyIdentity,
      icon: <IdcardOutlined className="nav-item-icon" />,
      activeIcon: <FileDoneOutlined className="nav-item-icon" />
    },
    {
      title: 'Description Builder',
      route: EnumRoute.DescriptionBuilder,
      icon: <ReconciliationOutlined className="nav-item-icon" />,
      activeIcon: <ReconciliationOutlined className="nav-item-icon" />
    },
    {
      title: 'Live Auction Browser',
      onClick: () => window.open('https://scout.pacer.co', '_blank'),
      icon: <CompassOutlined className="nav-item-icon" />,
      activeIcon: <CompassOutlined className="nav-item-icon" />
    }
  ]

  return (
    <div className="top-bar">
      <div className="top-bar__content">
        <div className="top-bar__background">
          <img src={background} alt="Background" className="top-bar__background-image" />
          <MenuOutlined
            className="top-bar__menu-icon"
            onClick={handleMenuClick}
            style={{
              fontSize: 24, color: '#fff', cursor: 'pointer', marginLeft: 16, zIndex: 1
            }}
          />
          <Link to={EnumRoute.Home} className="top-bar__logo">
            <img alt="Pacer Logo" src={theme === EnumTheme.Dark ? logoWhite : logo} />
          </Link>
        </div>
        <div className="top-bar__header__items">
          <div className="top-bar__account-icon">
            <Avatar
              size={40}
              icon={<UserOutlined />}
              style={{ backgroundColor: '#87d068', cursor: 'pointer' }}
              onClick={() => navigate(EnumRoute.Account)}
            />
          </div>
        </div>
      </div>
      <Drawer
        placement="left"
        onClose={handleDrawerClose}
        open={isDrawerVisible}
        className="side-drawer"
        width={300}
      >
        <div className="side-drawer__header">
          <Link to={EnumRoute.Home} className="side-drawer__logo">
            <img alt="Pacer Logo" src={theme === EnumTheme.Dark ? logoWhite : logo} />
          </Link>
          <Avatar
            size={40}
            icon={<UserOutlined />}
            style={{ backgroundColor: '#87d068', cursor: 'pointer', marginLeft: 'auto' }}
            onClick={() => {
              navigate(EnumRoute.Account)
              setIsDrawerVisible(false)
            }}
          />
        </div>

        <Menu
          mode="inline"
          openKeys={openKeys}
          onOpenChange={handleSubMenuChange}
          className="side-drawer__menu"
        >
          <Menu.Item
            key="home"
            icon={<HomeOutlined />}
            onClick={() => {
              navigate(EnumRoute.Home)
              setIsDrawerVisible(false)
            }}
          >
            Home
          </Menu.Item>
          <Menu.SubMenu key="features" icon={<PlusSquareOutlined />} title="Features">
            {topNavItems.map((item) => (
              <Menu.Item
                key={item.title.toLowerCase()}
                icon={item.icon}
                onClick={() => {
                  if (item.route) {
                    navigate(item.route)
                  } else if (item.onClick) {
                    // Call the onClick function if it exists
                    item.onClick()
                  } else {
                    // If no route and no onClick, log or handle as needed
                    console.warn('No navigation defined for item: ')
                  }
                  setIsDrawerVisible(false)
                }}
              >
                {item.title}
              </Menu.Item>
            ))}
          </Menu.SubMenu>

          <Menu.Item
            key="account"
            icon={<UserOutlined />}
            onClick={() => {
              navigate(EnumRoute.Account)
              setIsDrawerVisible(false)
            }}
          >
            Account
          </Menu.Item>
          <Menu.Item
            key="contact"
            icon={<MailOutlined />}
            onClick={() => {
              window.location.href = 'mailto:info@pacer.co'
              setIsDrawerVisible(false)
            }}
          >
            Contact Us
          </Menu.Item>
        </Menu>
      </Drawer>
    </div>
  )
}
