import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { MailOutlined } from '@ant-design/icons'
import {
  Col, Form, Row, Typography
} from 'antd'
import {
  signInWithEmailAndPassword,
  User
} from 'firebase/auth'

import { Button } from 'src/components/Button'
import { HeaderLayout } from 'src/components/HeaderLayout'
import { Input, PasswordInput } from 'src/components/Input'
import { MigrateModal } from 'src/components/Modal'
import LoadingModal from 'src/components/Modal/LoadingModal'
import { getFirebaseAuth } from 'src/firebase/firebase'
import { useAuthen } from 'src/hooks/useAuthen'
import { EnumRoute, EnumRouteSearchParam } from 'src/router/types'
import { store } from 'src/store/store'
import { EnumTransactionAction } from 'src/store/types'
import { ALLOW_ANONYMOUS_USERS, EMAIL_REGEX } from 'src/utils/constants'
import { authApiAxiosWithRefresh } from 'src/utils/customAxios'

import { useStripe } from '../../hooks/useStripe'

import './styles.scss'

const { Title, Text } = Typography

export default function SignInPage() {
  const globalStore = useContext(store)
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()

  const { isAnonymous, isAuthenLoaded, currentUser } = useAuthen()
  const { fetchStripePublicKey } = useStripe()

  const [errMessage, setErrMessage] = useState<string>('')
  const [loggingInModalVisible, setLoggingInModalVisible] = useState(false)
  const [showMigrateModal, setShowMigrateModal] = useState(false)
  const [migratingModalVisible, setMigratingModalVisible] = useState(false)
  const [showMigrateFailureModal, setShowMigrateFailureModal] = useState(false)
  // const [showSignInForm, setShowSignInForm] = React.useState(false)

  useEffect(() => {
    if (isAuthenLoaded) {
      if (!isAnonymous) {
        if (searchParams.has(EnumRouteSearchParam.RedirectUrl) && searchParams.get(EnumRouteSearchParam.RedirectUrl)) {
          const redirectRoute = searchParams.get(EnumRouteSearchParam.RedirectUrl)!
          navigate(redirectRoute)
        } else {
          navigate(EnumRoute.Account)
        }
        return
      }
      globalStore.dispatch({
        type: EnumTransactionAction.FetchPreviousTransactions
      })
    }
  }, [searchParams])

  useEffect(() => {
  }, [globalStore.state.previousTransactions.transactions])

  const onLoginSuccess = async (user: User) => {
    // Note: Since we are storing things (read: transactions) locally we will
    //       have to either prompt the user to back up or ignore all locally
    //       stored transactions as part of their account.

    // Either ask the user to migrate or just do the stuff
    globalStore.dispatch({
      type: 'SIGN_IN',
      user,
      token: user.getIdToken(),
      refreshToken: user.refreshToken,
      isAnonymous: false
    })
    if (globalStore.state.previousTransactions.transactions.length > 0) {
      setShowMigrateModal(true)
    } else {
      await setStoreFetchAndGo()
    }

    fetchStripePublicKey()
    setLoggingInModalVisible(false)
  }

  const setStoreFetchAndGo = async () => {
    // Well we signed in and can store stuffs and all that jazz
    // Get the user's phone number if we have it
    globalStore.dispatch({
      type: 'FETCH_USER_PHONE',
      store: globalStore
    })

    // Go fetch all the transactions out there
    globalStore.dispatch({
      type: 'FETCH_PREVIOUS_TRANSACTIONS',
      store: globalStore
    })
    globalStore.dispatch({
      type: 'FETCH_PENDING_TRANSACTIONS',
      store: globalStore
    })

    // Now register for push notifications and update the backend
    // TODO Lanh
    // registerAndStorePushNotificationsAsync().catch((error) => {
    //   console.error('Failed to register for push notifications. Error:', error);
    // });

    setTimeout(() => {
      if (searchParams.has(EnumRouteSearchParam.RedirectUrl) && searchParams.get(EnumRouteSearchParam.RedirectUrl)) {
        const redirectRoute = searchParams.get(EnumRouteSearchParam.RedirectUrl)!
        navigate(redirectRoute)
      } else {
        navigate(ALLOW_ANONYMOUS_USERS ? EnumRoute.Account : EnumRoute.Home)
      }
    }, 1)
  }

  const migrateThenSetStoreFetchAndGo = async () => {
    try {
      // First migrate
      setShowMigrateModal(false)
      setMigratingModalVisible(true)
      await (
        await authApiAxiosWithRefresh()
      ).post('/transaction/migrate', globalStore.state.previousTransactions)

      // // Then fetch and go
      setMigratingModalVisible(false)
      setStoreFetchAndGo()
    } catch (err) {
      setShowMigrateFailureModal(true)
    }
  }

  const handleIgnoreMigration = () => {
    setStoreFetchAndGo()
    setShowMigrateModal(false)
  }

  const onLoginFailure = (errorMessage: string) => {
    setErrMessage(errorMessage)
    setLoggingInModalVisible(false)
  }

  const signInWithEmail = async ({ email, password }: { email: string; password: string }) => {
    setLoggingInModalVisible(true)
    const firebaseAuth = getFirebaseAuth()
    // await signOut(firebaseAuth)
    signInWithEmailAndPassword(firebaseAuth, email, password)
      .then((userCred) => {
        onLoginSuccess(userCred.user)
      })
      .catch((error) => {
        const errorCode = error.code
        let errorMessage = error.message
        switch (errorCode) {
        case 'auth/weak-password':
          errorMessage = 'Password is too weak!'
          break
        case 'auth/user-not-found':
        case 'auth/invalid-email':
          errorMessage = 'Please check the entered email.'
          break
        case 'auth/wrong-password':
          errorMessage = 'The entered password is invalid.'
          break
        case 'auth/user-disabled':
          errorMessage = 'User account does not exist.'
          break
        default:
          break
        }
        onLoginFailure(errorMessage)
      })
  }

  return (
    <HeaderLayout title="Sign In" hasGoBack>
      <Row className="sign-in-page">
        <MigrateModal
          open={showMigrateModal}
          onOk={migrateThenSetStoreFetchAndGo}
          onCancel={handleIgnoreMigration}
        />
        {
          migratingModalVisible && <LoadingModal title="Migrating..." />
        }
        <Col span={20} offset={2}>
          {
            !isAuthenLoaded ? (
              <LoadingModal title="Loading..." />
            ) : (
              <>
                {
                  loggingInModalVisible && (
                    <LoadingModal title="Logging in..." />
                  )
                }
                <Title className="sign-in-page__title" level={2}>Pacer</Title>
                <div className="sign-in-page__subtitle">Pacer combines speed, intelligence, and a seamless digital experience for simple automotive transactions.</div>
                <Form
                  className="sign-in-form kuaay-form"
                  name="signin"
                  initialValues={{ email: '', password: '' }}
                  onFinish={signInWithEmail}
                >
                  <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: 'Please input your Email' },
                      { pattern: EMAIL_REGEX, message: 'Incorrect email format' }
                    ]}
                  >

                    <Input
                      className="kuaay-input-v2"
                      placeholder="Email"
                      autoCapitalize="none"
                      type="email"
                      inputMode="email"
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Please input your Password.' }]}
                  >
                    <PasswordInput
                      className="kuaay-password-input-v2"
                      placeholder="Password"
                      autoCapitalize="none"
                    />
                  </Form.Item>
                  <Text
                    type="danger"
                  >
                    {errMessage}
                  </Text>
                  <Form.Item className="sign-in-button-wrapper">

                    <Button
                      className="sign-in-button"
                      icon={<MailOutlined />}
                      type="primary"
                      htmlType="submit"
                    >
                      Sign In
                    </Button>
                  </Form.Item>
                </Form>
                <Link
                  to={EnumRoute.SignUp + (searchParams.toString() ? `?${searchParams.toString()}` : '')}
                  className="sign-up-link"
                >
                  Don&apos;t have an account?
                </Link>
              </>
            )
          }
        </Col>
      </Row>
    </HeaderLayout>
  )
}
